// // Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
// import {getAuth} from "firebase/auth";
// import {getFirestore} from "firebase/firestore";
// // TODO: Add SDKs for Firebase products that you want to use
// // https://firebase.google.com/docs/web/setup#available-libraries

// // Your web app's Firebase configuration
// const firebaseConfig = {
//   apiKey: "AIzaSyAV12in47IwT6i7Dq0TqhZikWcLn9faktI",
//   authDomain: "logtest-85130.firebaseapp.com",
//   projectId: "logtest-85130",
//   storageBucket: "logtest-85130.appspot.com",
//   messagingSenderId: "827672218331",
//   appId: "1:827672218331:web:ba15a9129e5401d99a9afe",
//   measurementId: "G-TB53H26J7H"
// };

// // Initialize Firebase
// const app = initializeApp(firebaseConfig);
// const db = getDatabase(app);
// export const auth=getAuth();
// export const db=getFirestore(app);
// export default app;




// firebase.js
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database"; // For Realtime Database
import { getFirestore } from "firebase/firestore"; // For Firestore

const firebaseConfig = {

  apiKey: "AIzaSyB-JHJQVXWetm5IzrAAHbavvV3KcFYe1Uk",

  authDomain: "cscumt1910lawk.firebaseapp.com",

  databaseURL: "https://cscumt1910lawk-default-rtdb.firebaseio.com",

  projectId: "cscumt1910lawk",

  storageBucket: "cscumt1910lawk.appspot.com",

  messagingSenderId: "758128808237",

  appId: "1:758128808237:web:42d9c9550eee5be3f803a4",

  measurementId: "G-18ZDFLGXW0"

};















// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Realtime Database and Firestore
const dbRealtime = getDatabase(app); // Realtime Database
const dbFirestore = getFirestore(app); // Firestore

// Initialize Authentication
const auth = getAuth(app);

export { app, auth, dbRealtime, dbFirestore };
